$theme: ekonomi;
@use "sass:math";
@use "design-system" as ds;

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: max(100vw, 1920px);
  max-height: max(100vh, 1080px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  overflow: hidden;
  @include ds.background(primary, $theme: $theme);

  & > .body {
    position: relative;
    width: 100%;
    height: 100%;

    & iframe {
      width: 100%;
      height: 100%;
      width: 100vw;
      height: 100vh;
      width: 100vi;
      height: 100vb;
    }
  }

  & > .header {
    display: flex;
    @include ds.background(primary, $theme: $theme);
    justify-content: space-between;
  }
}

.button {
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  align-items: center;
  @include ds.typography(h4, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  gap: ds.spacing(base);
  padding: ds.spacing();

  & > svg {
    width: auto;
    height: auto;
    padding-left: ds.spacing(regular);
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.hiddenText {
  @include ds.a11y-hidden();
}

.headerLogo {
  pointer-events: all;
  margin: ds.spacing();
}

.coreLogo {
  background-image: url(/images/logo-core.png);
  background-size: contain;
  width: 70px;
  height: 22px;
  background-repeat: no-repeat;

  @include ds.darkmode {
    background-image: url(/images/logo-core-darkmode.png);
  }

  @media (min-width: ds.$screen-size--small) {
    width: math.ceil(70px * ds.$desktop-upscaling-factor);
    height: math.ceil(22px * ds.$desktop-upscaling-factor);
  }
}

.economyLogo {
  background-image: url(/images/logo-ekonomi.png);
  background-size: contain;
  width: 179px;
  height: 22px;
  margin: auto 0;
  background-repeat: no-repeat;

  @include ds.darkmode {
    background-image: url(/images/logo-ekonomi-darkmode.png);
  }

  @media (min-width: ds.$screen-size--small) {
    width: math.ceil(179px * ds.$desktop-upscaling-factor);
    height: math.ceil(22px * ds.$desktop-upscaling-factor);
  }
}

.hidden {
  display: none;
}
