$theme: ekonomi;
@use "design-system" as ds;

.latestNewsArticleContainer {
  border: 1px solid black;
  @include ds.border(
    divider--subtle,
    $t: 0,
    $b: 0,
    $l: 1px,
    $r: 1px,
    $theme: $theme
  );

  &.firstArticle {
    @include ds.border(
      divider--subtle,
      $t: 1px,
      $l: 1px,
      $r: 1px,
      $theme: $theme
    );

    border-radius: 4px 4px 0 0;
  }

  &.lastArticle {
    @include ds.border(
      divider--subtle,
      $b: 1px,
      $l: 1px,
      $r: 1px,
      $theme: $theme
    );

    border-radius: 0 0 4px 4px;
  }
}

.separator {
  @include ds.border(divider--subtle, $t: 0, $b: 1px, $theme: $theme);
  margin: 0 ds.spacing();

  &.beforeBox {
    margin: 0;
  }
}
