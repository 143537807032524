$theme: ekonomi;
@use "design-system" as ds;

.storyVignette {
  @include ds.pad-lr();
  @include ds.stack();
  @include ds.typography(overline, $theme);
  text-transform: uppercase;
  --opentype-case: "case" on;

  &.longform,
  &.explaining {
    @include ds.stack(medium, $min-screen-size: small);

    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }
  }

  &.licensedArticle,
  &.longform {
    @include ds.background(tertiary, $theme: $theme);
  }
}

.titleLink {
  @include ds.text-color(brand, $theme: $theme);

  &.fadedText {
    @include ds.text-color(secondary, $theme: $theme);
  }

  &.premium {
    @include ds.text-color(premium, $theme: $theme);
    &.longform {
      color: ds.color(text, premium, $theme: $theme, $mode: "dark");
    }
  }

  &.uncoloredLink {
    @include ds.text-color(primary, $theme: $theme);
  }
}

.suffix {
  @include ds.text-color(primary, $theme: $theme);
  &.licensedArticle,
  &.longform {
    @if $theme == ekonomi {
      color: ds.color(text, tertiary, $theme: $theme, $mode: "dark");
    }
  }

  &.fadedText {
    @include ds.text-color(secondary, $theme: $theme);
  }

  &.uncoloredLink {
    @include ds.text-color(primary, $theme: $theme);
  }
}
