$theme: ekonomi;
@use "design-system" as ds;

.textContainer {
  @include ds.typography(body, $theme);
  @include ds.pad-lr;
  @include ds.stack();

  // Let's use lining nums here if available as it's more readable
  // in a lot of cases in Omni Ekonomi for example
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_fonts/OpenType_fonts_guide
  font-variant-numeric: lining-nums;
  font-feature-settings: "lnum" 1;

  // Prevent orphans
  text-wrap: pretty;

  &.originalLead {
    & p {
      text-align: center;
      text-wrap: balance;
    }
    @include ds.typography(premium-h6, $theme: $theme);
  }
}

.licensedArticle {
  @include ds.stack(regular-increased);
  @include ds.background(tertiary, $theme: $theme);
  @include ds.text-color(tertiary, $theme: $theme);
}

.longform,
.explaining {
  @include ds.typography(premium-body, $theme, false);

  & p {
    @include ds.stack(base);

    &:first-child {
      @include ds.stack(none);
    }
  }
  @media (min-width: ds.$screen-size--small) {
    @include ds.pad-lr(medium);
  }
}

.news,
.native {
  & p {
    @include ds.stack(none);
    text-indent: 1em;

    &:first-child {
      text-indent: 0;
    }
  }
}

.externalLink {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: ds.color(ui, brand, $theme: $theme);
  &:hover {
    @include ds.text-color(brand, $theme: $theme);
  }
}
