$theme: ekonomi;
@use "design-system" as ds;

.unSupported {
  padding: ds.spacing();
  @include ds.border(divider, $a: 2px, $theme: $theme);
  border-style: dashed;
  @include ds.darkmode {
    @include ds.background(tertiary, $theme: $theme);
  }
  @include ds.text-color(primary, $theme: $theme);

  @include ds.pad-lr();
  margin: ds.spacing() ds.spacing() 0 ds.spacing();
}
