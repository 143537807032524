$theme: ekonomi;
@use "design-system" as ds;

.salesPoster {
  margin-top: ds.spacing(regular-increased);
  @include ds.text-color(primary, $theme: $theme);

  @include ds.background(card--commercial, $theme: $theme);
  padding: ds.spacing(huge) + ds.spacing() ds.spacing() ds.spacing(huge)
    ds.spacing();
  position: relative;

  &:before {
    content: "";
    @include ds.background(primary, $theme: $theme);
    left: -100px;
    right: -100px;
    top: -20px;
    height: 400px;
    clip-path: ellipse(50% 10% at top);
    position: absolute;
  }
}

.appIcon {
  position: relative;
  top: -90px;
  margin-bottom: -60px;
  text-align: center;

  & img {
    width: 60px;
    height: 60px;
  }
}

.title {
  @include ds.typography(heading-3xl--messaging, $theme);
  text-wrap: balance;
  text-align: center;
}

.usps {
  @include ds.stack(medium);
  @include ds.typography(body-base--messaging, $theme);
  @extend %reset-list-styles;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  & li {
    @include ds.stack(base);
    list-style: none;
    display: flex;
    justify-content: center;

    & i {
      position: relative;
      @if $theme == core {
        top: 3px;
      }
      @if $theme == ekonomi {
        top: 4px;
      }
      margin-right: ds.spacing(base);
      @include ds.colorise-icon(text, premium, $theme: $theme);
    }
  }
}
