$theme: ekonomi;
@use "design-system" as ds;

.map {
  @include ds.stack();
  @include ds.pad-lr(medium);

  &.unGrouped {
    @include ds.pad-lr();
  }

  &.mainMap {
    padding: 0;
    @include ds.stack(none);
  }
}
