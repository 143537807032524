$theme: ekonomi;
@use "design-system" as ds;

.answerContainer {
  @include ds.pad-lr();
  @include ds.stack(regular-increased);

  &.longform,
  &.explaining {
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }
    @include ds.typography(premium-body, $theme, false);

    & p {
      @include ds.stack(base);

      &:first-child {
        @include ds.stack(none);
      }
    }
  }
  &.news {
    & p {
      @include ds.stack(none);
      text-indent: 1em;

      &:first-child {
        text-indent: 0;
      }
    }
  }
}

.author {
  display: flex;
  align-items: center;
  gap: ds.spacing();
}

.authorImage {
  border-radius: 50%;
  object-fit: cover;
  width: 72px;
  height: 72px;
}

.authorDetails {
  display: flex;
  flex-direction: column;
  gap: ds.spacing(atomic);
}

.authorName {
  @include ds.typography(h2, $theme);
  font-style: normal;
}

.authorTitle {
  @include ds.typography(h6, $theme);
}

.quote {
  margin: ds.spacing(regular-increased) 0 0 0;
  @include ds.typography(body, $theme);
}
