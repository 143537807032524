$theme: ekonomi;
@use "design-system" as ds;

.native {
  @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
}

.groupedResources {
  @include ds.border(brand, $l: 2px, $theme: $theme);
  margin-top: ds.spacing();
  padding-top: 0;

  margin-left: ds.spacing();

  @media (min-width: ds.$screen-size--small) {
    margin-left: ds.spacing(medium);
  }

  &.premium {
    @include ds.border(premium, $l: 2px, $theme: $theme);
  }

  &.native {
    @include ds.border(native, $l: 2px, $theme: $theme);

    &:before {
      @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
    }
  }

  // Hack used to align border with the text horizontally
  position: relative;
  &:before {
    content: "";
    @include ds.background(primary, $theme: $theme);
    position: absolute;
    top: 0;
    left: -3px;
    width: 3px;
    height: 20px;
  }
}
