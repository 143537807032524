$theme: ekonomi;
@use "design-system" as ds;

.factContainer {
  @include ds.background(card--subtle, $theme: $theme);
  border-radius: ds.$border-radius--default;
  padding: ds.spacing();

  margin: ds.spacing(regular-increased) ds.spacing() 0 ds.spacing();

  &.explaining,
  &.longform {
    @media (min-width: ds.$screen-size--small) {
      margin-left: ds.spacing("medium");
      margin-right: ds.spacing("medium");
    }
  }
}

.title {
  @include ds.typography(h2, $theme);
}

.body {
  @include ds.typography(h6, $theme);

  & p {
    @include ds.stack(base);
  }
}

.native {
  @include ds.border(divider, $a: 1px, $theme: $theme);
  background-color: transparent;
}
