$theme: ekonomi;
@use "design-system" as ds;

.quoteContainer {
  padding: ds.spacing(medium) ds.spacing() ds.spacing() ds.spacing();
  @media (min-width: ds.$screen-size--small) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @extend %reset-blockquote-styles;
  @include ds.typography(quote, $theme);
  @include ds.text-color(primary, $theme: $theme);
  font-style: italic;
  text-align: center;
}

.quoteFooter {
  @include ds.stack(base);
  margin: 0;
  @include ds.typography(underline, $theme);
  @include ds.text-color(secondary, $theme: $theme);
  font-style: normal;
}

.quoteCite {
  font-style: normal;
}
